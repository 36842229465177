import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/showlogin',
    name: 'showlogin',
    component: () => import('../views/login/showlogin.vue'),
    meta: {
      title: 'showlogin',
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue'),
    meta: {
      title: '登录',
    }
  },
  {
    path: '/reg',
    name: 'reg',
    component: () => import('../views/login/reg.vue'),
    meta: {
      title: '注册',
    }
  },
  {
    path: '/repwd',
    name: 'repwd',
    component: () => import('../views/login/repwd.vue'),
    meta: {
      title: '忘记密码',
    }
  },
  {
    path: '/ganen',
    name: 'ganen',
    component: () => import('../views/ganen.vue'),
    meta: {
      title: 'ganen',
    }
  },
  {
    path: "/article/:articleType/:articleId?",
    name: "article",
    component: () => import("@/views/art/article.vue"),
    meta: {
      title: "内容",
      active: "article",
    },
    props: true,
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('../views/user/share.vue'),
    meta: {
      title: '邀请会员',
    }
  },
  {
    path: '/nftDetail',
    name: 'nftDetail',
    component: () => import('../views/nftDetail/nftDetail.vue'),
    meta: {
      title: 'NFT详情',
    }
  },
  {
    path: '/nftDetail1',
    name: 'nftDetail1',
    component: () => import('../views/nftDetail/nftDetail1.vue'),
    meta: {
      title: 'NFT详情',
    }
  },
  {
    path: '/myCollect',
    name: 'myCollect',
    component: () => import('../views/myCollect/myCollect.vue'),
    meta: {
      title: '我的收藏',
    }
  },
  {
    path: '/goodsList',
    name: 'goodsList',
    component: () => import('../views/my/goodsList.vue'),
    meta: {
      title: '我的碎片',
    }
  },
  {
    path: '/myCollect2',
    name: 'myCollect2',
    component: () => import('../views/myCollect/myCollect2.vue'),
    meta: {
      title: '兑换NFT',
    }
  },
  {
    path: '/pledgeList',
    name: 'pledgeList',
    component: () => import('../views/my/pledgeList.vue'),
    meta: {
      title: '质押NFT',
    }
  },
  {
    path: '/newsList',
    name: 'newsList',
    component: () => import('../views/newsList/newsList.vue'),
    meta: {
      title: '头条公告',
    }
  },
  {
    path: '/newsList1',
    name: 'newsList1',
    component: () => import('../views/newsList/newsList1.vue'),
    meta: {
      title: '关于发行',
    }
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('../views/newsList/notice.vue'),
    meta: {
      title: '',
    }
  },
  {
    path: '/userHome',
    name: 'userHome',
    component: () => import('../views/userHome/userHome.vue'),
    meta: {
      title: '',
    }
  },
  {
    path: '/buy',
    name: 'buy',
    component: () => import('../views/position/buy.vue'),
    meta: {
      title: '买入',
    }
  },
  {
    path: '/sell',
    name: 'sell',
    component: () => import('../views/position/sell.vue'),
    meta: {
      title: '卖出',
    }
  },
  {
    path: '/hangout',
    name: 'hangout',
    component: () => import('../views/position/hangOut.vue'),
    meta: {
      title: '挂单',
    }
  },
  {
    path: '/obuy',
    name: 'obuy',
    component: () => import('../views/user/obuy.vue'),
    meta: {
      title: '一键买入',
    }
  },
  {
    path: '/osell',
    name: 'osell',
    component: () => import('../views/user/osell.vue'),
    meta: {
      title: '一键买入',
    }
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('../views/collect/orderDetail.vue'),
    meta: {
      title: '支付详情',
    }
  },
  {
    path: '/c2cpay',
    name: 'c2cpay',
    component: () => import('../views/collect/c2cpay.vue'),
    meta: {
      title: 'C2C支付',
    }
  },
  {
    path: '/merchant',
    name: 'merchant',
    component: () => import('../views/my/merchant.vue'),
    meta: {
      title: '成为商家',
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/my/auth.vue'),
    meta: {
      title: '实名认证',
    }
  },
  {
    path: '/certification',
    name: 'certification',
    component: () => import('../views/my/certification.vue'),
    meta: {
      title: '高级实名认证',
    }
  },
  {
    path: '/mynft',
    name: 'mynft',
    component: () => import('../views/my/myNft.vue'),
    meta: {
      title: 'mynft',
    }
  },
  {
    path: '/editInfo',
    name: 'editInfo',
    component: () => import('../views/my/editInfo.vue'),
    meta: {
      title: '编辑信息',
    }
  },
  {
    path: '/editName',
    name: 'editName',
    component: () => import('../views/my/editName.vue'),
    meta: {
      title: '编辑姓名',
    }
  },
  {
    path: '/editPwd',
    name: 'editPwd',
    component: () => import('../views/my/editPwd.vue'),
    meta: {
      title: '修改交易密码',
    }
  },
  {
    path: '/editPwd1',
    name: 'editPwd1',
    component: () => import('../views/my/editPwd1.vue'),
    meta: {
      title: '修改登录密码',
    }
  },
  {
    path: '/OTCtrading',
    name: 'OTCtrading',
    component: () => import('../views/my/OTCtrading.vue'),
    meta: {
      title: 'OCT交易',
    }
  },
  {
    path: '/OTCrecords',
    name: 'OTCrecords',
    component: () => import('../views/my/OTCrecords.vue'),
    meta: {
      title: 'OCT记录',
    }
  },
  {
    path: '/OTCDetails',
    name: 'OTCDetails',
    component: () => import('../views/my/OTCDetails.vue'),
    meta: {
      title: 'OCT详情',
    }
  },
  {
    path: '/NFTimport',
    name: 'NFTimport',
    component: () => import('../views/my/NFTimport.vue'),
    meta: {
      title: 'NFT转入',
    }
  },
  {
    path: '/NFTimportRecords',
    name: 'NFTimportRecords',
    component: () => import('../views/my/NFTimportRecords.vue'),
    meta: {
      title: 'NFT转入记录',
    }
  },
  {
    path: '/NFTtransfer',
    name: 'NFTtransfer',
    component: () => import('../views/my/NFTtransfer.vue'),
    meta: {
      title: 'NFT转移',
    }
  },
  {
    path: '/NFTtransferRecords',
    name: 'NFTtransferRecords',
    component: () => import('../views/my/NFTtransferRecords.vue'),
    meta: {
      title: 'NFT转移记录',
    }
  },
  {
    path: '/pledge',
    name: 'pledge',
    component: () => import('../views/pledge/pledge.vue'),
    meta: {
      title: '质押NFT',
    }
  },
  {
    path: '/pledgeRecord',
    name: 'pledgeRecord',
    component: () => import('../views/pledge/pledgeRecord.vue'),
    meta: {
      title: '质押记录',
    }
  },
  {
    path: '/teamList',
    name: 'teamList',
    component: () => import('../views/my/teamList.vue'),
    meta: {
      title: '团队列表',
    }
  },
  {
    path: '/royaltyDetails',
    name: 'royaltyDetails',
    component: () => import('../views/my/royaltyDetails.vue'),
    meta: {
      title: '版税明细',
    }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/my/account.vue'),
    meta: {
      title: '账户绑定',
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/myCollect/search.vue'),
    meta: {
      title: '搜索',
    }
  },
  {
    path: '/create',
    name: 'create',
    component: () => import('../views/my/create.vue'),
    meta: {
      title: '制作',
    }
  },
  {
    path: '/createLog',
    name: 'createLog',
    component: () => import('../views/my/createLog.vue'),
    meta: {
      title: '制作记录',
    }
  },

  {
    path: '/tabbar',
    name: 'tabbar',
    redirect: '/index',
    component: () => import('../views/tabbar/tabbar.vue'),
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('../views/index/index.vue'),
        meta: {
          title: '首页',
        }
      },
      {
        path: '/art',
        name: 'art',
        component: () => import('../views/art/art.vue'),
        meta: {
          title: '艺术品',
        }
      },
      {
        path: '/position',
        name: 'position',
        component: () => import('../views/position/position.vue'),
        meta: {
          title: '持仓',
        }
      },
      {
        path: '/collect',
        name: 'collect',
        component: () => import('../views/collect/collect.vue'),
        meta: {
          title: '收藏',
        }
      },
      {
        path: '/my',
        name: 'my',
        component: () => import('../views/my/my.vue'),
        meta: {
          title: '我的',
        }
      },
    ]
  },
  {
    path: '/:path(.*)',
    name: 'notFound',
    component: () => import('../views/notFound.vue'),
    meta: {
      title: '找不到页面'
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
